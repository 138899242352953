import { Route, Routes } from "react-router-dom";
// import MainLayout from "./pages/MainLayout";
import { YMInitializer } from 'react-yandex-metrika';
import HomePage from "./pages/HomePage";
import EventsPage from "./pages/EventsPage";
import EventsInnerPage from "./pages/EventsInnerPage";
import LibraryPage from "./pages/LibraryPage";
import VideosPage from "./pages/VideosPage";
import { VideoInnerPage } from "./pages/VideoInnerPage";
import PublicationsPage from "./pages/PublicationsPage/index.js";
import { PublicationsInnerPage } from "./pages/PublicationsInnerPage";
import PharmNewsPage from "./pages/PharmNewsPage";
import { PharmNewsInnerPage } from "./pages/PharmNewsInnerPage";
import ForPharmaciesPage from "./pages/ForPharmaciesPage";
import { ForPharmaciesInnerPage } from "./pages/ForPharmaciesInnerPage";
import SpecprojectsPage from "./pages/SpecprojectsPage";
import { SpecprojectsInnerPage } from "./pages/SpecprojectsInnerPage";
import { ContactsPage } from "./pages/ContactsPage";
import SpeakersPage from "./pages/SpeakersPage";
import LoginSpecProjectsPage from "./pages/LoginSpecProjectsPage";
import RegistrationSpecProjectsPage from "./pages/RegistrationSpecProjectsPage";
import AdSpecprojectsPage from "./pages/AdSpecprojectsPage";
import PrivateRoute from "./hooks/PrivateRoute";
import {AdSpecprojectsInnerPage} from "./pages/AdSpecprojectsInnerPage";

function App() {
  return (
    <div>
      <YMInitializer accounts={[87134681]} />
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
          <Route path="/" element={<HomePage />} />
          <Route path="events"  element={<EventsPage />} />
          <Route path="event/:id"  element={<EventsInnerPage />} />
          <Route path="library"  element={<LibraryPage />} />
          <Route path="videos"  element={<VideosPage /> } />
          <Route path="videos/:id"  element={<VideoInnerPage /> } />
          <Route path="publications"  element={<PublicationsPage />} />
          <Route path="publications/:id"  element={<PublicationsInnerPage/> } />
          <Route path="pharmnews"  element={<PharmNewsPage/> } />
          <Route path="pharmnews/:id"  element={<PharmNewsInnerPage/> } />
          <Route path="for-pharmacies"  element={<ForPharmaciesPage/> } />
          <Route path="for-pharmacies/:id"  element={<ForPharmaciesInnerPage/> } />
          <Route path="specprojects"  element={<SpecprojectsPage/> } />
          <Route path="specproject/:id"  element={<SpecprojectsInnerPage/> } />
          <Route path="speakers"  element={<SpeakersPage/> } />
          <Route path="/contacts"  element={<ContactsPage/> } />
          <Route path="/login-specprojects"  element={<LoginSpecProjectsPage/> } />
          <Route path="/registr-specprojects"  element={<RegistrationSpecProjectsPage/> } />
          {/* <Route path="ad-specprojects"  element={<AdSpecprojectsPage/> } /> */}
          <Route
              path="ad-specprojects"
              element={
                  <PrivateRoute>
                      <AdSpecprojectsPage/> 
                  </PrivateRoute>
              }
          />
          <Route
              path="ad-specprojects/:id"
              element={
                  <PrivateRoute>
                      <AdSpecprojectsInnerPage />
                  </PrivateRoute>
              }
          />
      </Routes>

    </div>
  );
}

export default App;
