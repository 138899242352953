/* eslint-disable */
import React, { useState } from 'react';
import { Box, Container, TextField, Typography, FormControl, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getToken, subscribeNews } from '../../services/sendPilseApi';
import theme from '../../theme';

const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    borderRadius: '30px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.light,
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '30px',
        color: 'white',
        width: '100%',
        input: {
            color: 'white',
        },
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
    },
});

const styles = {
    root: {
        background: 'black',
    },
    input: {
        color: 'white',
    },
};

const SubscribeNews = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submitted = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: theme.palette.primary.light,
                }}
            >
                <Typography variant="h3" sx={{ mb: 2 }}>
                    Вы подписались!
                </Typography>
            </Box>
        );
    };
    const errorMessage = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: theme.palette.primary.light,
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {errorMsg}
                </Typography>
            </Box>
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (Object.values(data).length === 0) {
            setError(true);
            setErrorMsg('Пожалуйста, заполните поля!');
            return;
        } else if (data.name && data.name.length < 2) {
            setError(true);
            setErrorMsg('Пожалуйста, проверьте имя!');
            return;
        } else if (data && data.name === undefined) {
            setError(true);
            setErrorMsg('Пожалуйста, введите имя!');
            return;
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
            setError(true);
            setErrorMsg('Ошибка, некорректный email!');
            return;
        } else if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(data.phone)) {
            setError(true);
            setErrorMsg('Ошибка, некорректный номер телефона!');
            return;
        } 
        else {
            setLoading(true);
            const bodyFormData = new FormData();

            bodyFormData.append('grant_type', 'client_credentials');
            bodyFormData.append('client_id', process.env.REACT_APP_CLIENT_ID);
            bodyFormData.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);

            const tokenData = await getToken(bodyFormData);

            let dataForm = new FormData();

            const dataObj = [
                {
                    email: data.email,
                    variables: {
                        Имя: data.name,
                        Телефон: data.phone
                    },
                },
            ];

            dataForm.append('emails', JSON.stringify(dataObj));

            await subscribeNews(dataForm, tokenData.access_token)
                .then((resp) => {
                    if (resp.result) {
                        setLoaded(true);
                    }
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    setLoaded(false);
                    setError(true);
                    setErrorMsg('Ошибка, попробуйте еще раз!');
                });
        }
    };

    return (
        <>
            <Box
                sx={{
                    background: 'linear-gradient(91.2deg, #A4CE52 5.64%, #10B8B4 73.27%)',
                    pt: 4,
                    pb: 6,
                    mt: -2
                }}
            >
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            color: theme.palette.primary.light,
                            width: '100%',
                        }}
                    >
                        <Typography variant="h3" sx={{ mb: 2, fontSize: { xs: '30px', md: '45px' } }}>
                            Будь в курсе новостей
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                textAlign: 'center',
                                // mb: 2,
                                fontSize: { xs: '16px', md: '24px' },
                            }}
                        >
                            Подпишитесь на нашу рассылку, чтобы получать полезные материалы:
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                textAlign: 'center',
                                mb: 2,
                                fontSize: { xs: '16px', md: '24px' },
                            }}
                        >
                            лекции, бесплатные вебинары и анонсы всех мероприятий.
                        </Typography>
                        {loading ? (
                            <CircularProgress color="inherit" />
                        ) : (
                            <>
                                {loaded ? (
                                    submitted()
                                ) : (
                                    <Box
                                        component="form"
                                        sx={{
                                            m: 1,
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        {error && errorMessage()}
                                        <CustomTextField
                                            id="outlined-basic"
                                            label="Имя"
                                            name="name"
                                            value={data.name || ''}
                                            onChange={handleChange}
                                            variant="outlined"
                                            sx={{ m: 1, color: 'white', width: { xs: '95%', md: 'auto' } }}
                                            InputProps={{
                                                styles: { color: '#fff' },
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#fff' },
                                            }}
                                        />
                                        <CustomTextField
                                            id="outlined-basic"
                                            label="e-mail"
                                            variant="outlined"
                                            name="email"
                                            value={data.email}
                                            onChange={handleChange}
                                            sx={{ m: 1, color: 'white', width: { xs: '95%', md: 'auto' } }}
                                            InputProps={{
                                                styles: { color: '#fff' },
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#fff' },
                                            }}
                                        />
                                        <CustomTextField
                                            id="outlined-basic"
                                            label="Телефон"
                                            variant="outlined"
                                            name="phone"
                                            value={data.phone}
                                            onChange={handleChange}
                                            sx={{ m: 1, color: 'white', width: { xs: '95%', md: 'auto' } }}
                                            InputProps={{
                                                styles: { color: '#fff' },
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#fff' },
                                            }}
                                        />
                                        <CustomButton
                                            onClick={handleSubmit}
                                            variant="outlined"
                                            size="large"
                                            sx={{ m: 1, py: 1.7, px: 3, width: { xs: '95%', md: 'auto' } }}
                                        >
                                            Подписаться
                                        </CustomButton>
                                    </Box>
                                )}
                            </>
                        )}

                        <Typography
                            variant="body"
                            sx={{
                                textAlign: 'center',
                                mt: 2,
                            }}
                        >
                            Нажимая кнопку Подписаться, &nbsp;
                            <a
                                href="https://drive.google.com/file/d/1dlC2gkCxBlnrc5pmN8sTqs8KmRd0-ZOk/view?usp=sharing"
                            >
                                <a target="_blank" style={{ color: theme.palette.primary.light, fontWeight: 700 }}>
                                    Вы соглашаетесь на обработку Ваших персональных данных
                                </a>
                            </a>
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default SubscribeNews;
