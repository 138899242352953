/* eslint-disable */
import React, {useState, useEffect} from 'react';
import MainLayout from '../MainLayout';
import { Box, Container, Typography } from '@mui/material';
import AdressIcon from '../../components/SvgImages/adressIcon';
import PhoneIcon from '../../components/SvgImages/phoneIcon';
import EmailIcon from '../../components/SvgImages/mailIcon';
import { styled } from '@mui/material/styles';
import SubscribeNews from '../../components/SubscribeNews';
import MainFaq from '../../components/FAQMain';
import { getAllFaqMain } from '../../services/FAQMain.service';
import { getAllBanners } from '../../services/Banner.service';
import InnerBanner from '../../components/InnerBanner';
import { Link } from 'react-router-dom';

const A = styled('a')(({ theme }) => ({
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    padding: '10px 0',
}));

export const ContactsPage = () => {
    const [faq, setFaq] = useState([])
    const [banner, setBanner] = React.useState();
    
    const fetchData = async () => {
      try {

        const respFaq = await getAllFaqMain();
        setFaq(respFaq)

        const banner = await getAllBanners();
        setBanner(banner);

      } catch (error) {
          console.log(error);
      }
    }

    useEffect(() => {
      fetchData()
    }, [])

    return (
        <>
            <MainLayout>
                <InnerBanner banner={banner} />
                <Container>
                    <Typography variant="h4" component="h1" gutterBottom align="left" sx={{ mt: 8, mb: 4 }}>
                        Контакты
                    </Typography>
                    {/* <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                        Группа компаний «Агентство Медицинского Маркетинга» специализируется на предоставлении услуг в
                        сфере рекламы и маркетинга фармацевтической продукции. Мы успешно работаем в этом направлении с
                        2006 г.
                    </Typography> */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <Box
                            sx={{
                                flex: '50%',
                            }}
                        >
                            {/* <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                <AdressIcon />
                                <Typography variant="body1" sx={{ fontWeight: 700, ml: 2 }}>
                                    230025, Республика Беларусь <br /> г. Гродно, ул. Мостовая, 39, офис 26
                                </Typography>
                            </Box> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                <Box component={'span'} sx={{pt:1}}><PhoneIcon /></Box>
                                <Typography variant="body1" sx={{ ml: 2 }}>
                                    тел.: <a href="tel:+375152620330">+375 152 620 330</a>
                                    <br />
                                    тел.: <a href="tel:+375297056362">+375 29 705 63 62</a>
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    // mt: 2,
                                    mb: 2,
                                }}
                            >
                               <Box component={'span'} sx={{pt:1}}> <EmailIcon /></Box>
                                <Typography variant="body1" sx={{ ml: 2, mb: 8 }}>
                                    <a href="mailto:admin@congress.by">admin@congress.by</a>
                                    <br />
                                    <a href="mailto:info@congress.by"> info@congress.by</a>
                                </Typography>
                            </Box>
                        </Box>
                        {/* <Box
                            sx={{
                                flex: '50%',
                            }}
                        >
                            <Typography variant="body1" sx={{ fontWeight: 700, mt: 2, mb: 2 }}>
                                Другие наши проекты
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mb: 6,
                                }}
                            >
                                <A href="https://am-market.com" target="_blank">
                                    am-market.com
                                </A>

                                <A href="https://pharmconsilium.com" target="_blank">
                                    pharmconsilium.com
                                </A>

                                <A href="https://amm-pharmgroup.com" target="_blank">
                                    amm-pharmgroup.com
                                </A>
                            </Box>
                        </Box> */}
                    </Box>
                </Container>
                <SubscribeNews />
                <Container>
                    <MainFaq faq={faq} />
                </Container>
            </MainLayout>
        </>
    );
};

